@use 'styles' as theme;
@import '../../../styles/mixins';

.image {
  pointer-events: none;
  user-select: none;
}

.description {
  overflow: hidden;
}
